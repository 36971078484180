import React, { useState } from 'react'
import QrReader from 'react-qr-scanner'
import ArrowBack from '../../../../commons/icons/ArrowBack'
import { ArrowLeft, IconCheckCircle, IconReload } from '../../../../commons/icons'
import { useNavigate } from 'react-router-dom'
import { useMutation } from 'react-query'
import wishlistService from '../../../../services/whislist'
import IconErrorCircle from '../../../../commons/icons/IconErrorCircle'

export default function ScanerQR() {
  const [isSuccessRead, setIsSuccessRead] = useState(false)
  const [isSuccess, setIsSuccess] = useState(false)
  const [isError, setIsError] = useState(false)
  const [loading, setLoading] = useState(false)

  const navigate = useNavigate()
  const handleError = (err: any) => {
    setIsError(true)
  }

  const handleScan = (res: any) => {
    if (res && res.text) {
      setIsSuccessRead(true)
      setLoading(true)
      searchMutation.mutate({ code: res.text })
    }
  }

  const searchMutation = useMutation({
    mutationFn: wishlistService.searchByDocument,
    onSuccess: (data) => {
      if (data.status == 200 && data.data.result.status == 1) {
        setIsError(false)
        setIsSuccess(true)
        setLoading(false)
        setTimeout(() => {
          window.location.href = `/wishlist/cliente/${data.data.result.content_event_id}/${data.data.result.id}`;
          // navigate(`/wishlist/cliente/${data.data.result.content_event_id}/${data.data.result.id}`)
        }, 900)
      } else showError()
    },
    onError: (e: any) => {
      showError()
    },
  })

  const resetScanner = () => {
    setIsError(false)
    setIsSuccess(false)
    setLoading(false)
    setIsSuccessRead(false)
  }

  const showError = () => {
    setIsError(true)
    setIsSuccess(false)
    setLoading(false)
  }

  return (
    <div className='bg-qr'>
      <div
        className='arrow-left-container'
        onClick={() => {

          // navigate('/wishlist/busqueda')
          window.location.href = '/wishlist/busqueda';
        }}
      >
        <ArrowLeft />
        <span className='text-white fs-3' style={{ cursor: 'pointer' }}>
          Regresar a búsqueda
        </span>
      </div>
      <div className='container d-flex flex-column justify-content-center d-flex align-items-center'>
        {!isSuccessRead && (
          <QrReader
            id='qr-generate'
            delay={100}
            style={{ with: 256, height: 256 }}
            onError={handleError}
            onScan={handleScan}
            constraints={{
              audio: false,
              video: { facingMode: "environment" }
            }}
          />
        )}
        {isSuccessRead && <img src='/media/imgs/QR_Code.svg' width='256' height='256' alt='' />}

        {loading && (
          <div className='validation-qr'>
            <span className='spinner-border text-light'></span>
            <span className='text-white'>Autorizando...</span>
          </div>
        )}

        {isSuccess && (
          <div className='validation-qr'>
            <IconCheckCircle width={111} height={111} />
            <span className='text-white'>Validado</span>
          </div>
        )}

        {isError && (
          <>
            <div className='validation-qr no-gap'>
              <IconErrorCircle width={135} height={135} />
              <span className='text-white'>Error</span>
            </div>

            <button
              type='button'
              id='kt_sign_in_scanner'
              className='btn btn-secondary'
              style={{ position: 'absolute', marginTop: 520 }}
              onClick={() => resetScanner()}
            >
              <div className='d-flex gap-2 align-items-center justify-content-center'>
                <IconReload />
                <span className='indicator-label tw-green-700'>Volver a intentar</span>
              </div>
            </button>
          </>
        )}
      </div>
    </div>
  )
}
